<template>
  <div>
    <v-container>
      <v-row>
        <v-col lg="12" class="pb-1">
          <s-crud
            add
            edit
            remove
            searchInput
            title="Tipo de Brevete / Vehiculo"
            :config="config"
            @save="save($event)"
            :filter="filter"
            ref="gridReceptionStorage"
            @rowSelected="rowSelected($event)"
          >
            <template slot-scope="props">
              <v-container>
                <v-row justify="center">
                  <v-col lg="3">
                    <s-select-definition
                      v-model="props.item.cTypeVehicleID"
                      :def="1131"
                      label="Tipo de Vehiculo"
                    />
                  </v-col>
                  <v-col lg="2">
                    <s-select-definition
                      v-model="props.item.cCategoryLicenseID"
                      :def="1139"
                      label="Categoria Brevete"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:SecStatus="{ row }">
                <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
                    {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
                </v-chip>
            </template> 
          </s-crud>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _sAssignLicenseVehicleService from "@/services/Technicalassistance/AssignLicenseVehicle";

export default {
  data: () => ({
    filter: {},
    config: {
      model: {
        cAssignLicenseID: "ID",
        SecStatus: "int"
      },
      service: _sAssignLicenseVehicleService,
      headers: [
        { text: "N°", value: "cNum" },
        { text: "Tipo de Vehiculo", value: "cTypeVehicleName" },
        { text: "Categoria Brevete", value: "cCategoryLicenseName" },
        { text: "Estado", value: "SecStatus" },
      ],
    },
  }),
  watch: {},

  created() {},
  methods: {
    rowSelected(val) {
        this.$emit("rowSelected", val);
    },

    save(val){
        val.SecStatus = 1;
        val.save();

        console.log(val);
    },
  },
};
</script>

<style></style>
